import {createSelector} from 'reselect';
import DateUtils from "../../Utils/DateUtils";
import IconUtils from "../../Utils/IconUtils";

export const programSelector = state => state.offlineDataState.programs;
export const projectSelector = state => state.offlineDataState.projects;
export const sampleEventSelector = state => state.offlineDataState.sampleEvents;

export const selectProjects = createSelector(
    [projectSelector, programSelector],
    (projects, programs) => projects.map(project => {
        const program = programs.find(program => String(program.hierarchyId) === String(project.programContextNodeId));
        const subProgram = programs.find(program => String(program.hierarchyId) === String(project.subProgramContextNodeId));

        return {
            ...project,
            programName: program?.name,
            subProgramName: subProgram?.name,
        }
    })
);

export const selectProject = createSelector(
    [projectSelector, (_, projectId) => projectId],
    (projects, projectId) => {
        return projects?.find(project => String(project.projectId) === String(projectId));
    }
);

export const selectAvailableSampleEvents = createSelector(
    [sampleEventSelector, projectSelector, programSelector],
    (sampleEvents, projects, programs) => {
        return sampleEvents.filter(event => !event.readOnly)
            .map(event => {
                const project = projects.find(project => String(project.projectId) === String(event.projectId));
                if(!project) {
                    console.log('warning: missing project for projectId ' + String(event.projectId) + ' for eventId ' + event.sampleEventId);
                    return null;
                }
                
                const subProgram = programs.find(program => String(program.hierarchyId) === String(project.parentHierarchyId));
                if(!subProgram) {
                    console.log('warning: missing subProgram for parentHierarchyId ' + String(project.parentHierarchyId) + ' for eventId ' + event.sampleEventId);
                    return null;
                }
                
                const sampleEventPhase = subProgram.SampleEventPhases.find(phase => phase.sampleEventPhaseId === event.sampleEventPhaseId);
                if(!sampleEventPhase) {
                    console.log('warning: missing sampleEventPhase for eventId ' + event.sampleEventId);
                    return null;
                }
                
                const procedure = subProgram.Procedures.find(procedure => procedure.procedureId === event.procedureId);
                const formattedStartDate = DateUtils.FormatToLocalDate(event.startDate, 'MMM d, yyyy');
                const shortName = `${formattedStartDate}${event.otherPurpose ? ' - ' + event.otherPurpose : ''}`;
                const fullName = `${shortName} - ${procedure.name}`;

                return {
                    ...event,
                    fullName,
                    shortName,
                    sampleEventPhaseName: sampleEventPhase.name,
                    procedureName: procedure.name,
                    procedureCode: procedure.code,
                    projectName: project.name,
                    formattedStartDate,
                    procedureIcon: IconUtils.GetIcon(procedure.code),
                    subProgramId: subProgram.programId,
                    programId: subProgram.parentProgramId,
                }
            })
            .filter(x => x);
    }
);

export const selectModifiedSampleEventHierarchyIds = createSelector(
    [selectAvailableSampleEvents],
    (sampleEvents) => (
        sampleEvents.filter(sampleEvent => (
                !!sampleEvent.offlineModifiedDate
                // || notYetUploadedPhotos.filter(photo => photo.sampleEventId === sampleEvent.sampleEventId).length > 0
        )).map(sampleEvent => sampleEvent.hierarchyId)
    )
)

export const selectSampleEventById = (state, id) => {
    return selectAvailableSampleEvents(state).find(event => String(event.sampleEventId) === String(id));
};

export const selectProgramByHierarchyId = createSelector(
    [programSelector, (state, hierarchyId) => hierarchyId],
    (programs, hierarchyId) => {
        return programs.find(program => String(program.hierarchyId) === String(hierarchyId));
});

export const selectProjectById = (state, id) => {
    return selectProjects(state).find(project => String(project.projectId) === String(id));
};

export const selectUsers = (state, projectId) => {
    const project = projectSelector(state).find(project => String(project.projectId) === String(projectId));

    return project?.Users ?? [];
};

export const selectProcedures = createSelector(
    [(state, hierarchyId) => selectProgramByHierarchyId(state, hierarchyId)],
    (program) => program?.Procedures ?? []
);

export const selectSampleEventPhaseTypes = createSelector(
    [(state, hierarchyId) => selectProgramByHierarchyId(state, hierarchyId)],
    (program) => program?.SampleEventPhases ?? []
);

