import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";

import {qualTools} from "../../../Constants/tools";
import ReturnButton from "../../Common/ReturnButton";
import {selectSurveysBySampleEventId} from "../../../Redux/Selectors/surveySelectors";
import {hasMonitoringRecommendations} from "../../../Utils/SurveyUtils";
import {SurveyQuestionAnswer} from "../../Common/Survey/SurveyQuestionAnswer";
import {selectQualitativeVegetationMonitoringBySampleEventId} from "../../../Redux/Selectors/qualitativeSelectors";


const QualitativeVegMonitoringReviewTable = (props) => {
    const {event} = props;

    const surveys = useSelector(state => selectSurveysBySampleEventId(state, event.sampleEventId));
    const answers = useSelector(state => selectQualitativeVegetationMonitoringBySampleEventId(state, event.sampleEventId));
    
    
    
    return (
        <div className="mt-3">
            {
                surveys.map(survey => {
                    const tool = qualTools.find(tool => tool.id === survey.surveyTypeId);

                    return (
                        <Fragment key={survey.surveyId}>
                            {
                                survey.SurveySections
                                    .filter(section => !!section.SurveyQuestions.length)
                                    .map(section => (
                                        <Fragment key={section.surveySectionId}>
                                            <div className="review-title">
                                                <span>{tool.title} - {section.name}</span>
                                                <ReturnButton path={`/event/${event.sampleEventId}/qual-veg/${section.surveyId}/${section.code}`} readOnly={event.isComplete}/>
                                            </div>
                                            <Table borderless responsive className="streambank-table half-table">
                                                <tbody>
                                                    {section.SurveyQuestions.filter(question => !hasMonitoringRecommendations(question))
                                                        .map((question, index) => (
                                                            <tr key={question.surveyQuestionId}>
                                                                <td key={1} className={question.isRequired ? 'required' : ''}>
                                                                    {index + 1}. {question.name}
                                                                </td>
                                                                <td key={2}>
                                                                    <SurveyQuestionAnswer
                                                                        question={question}
                                                                        answer={answers?.[question.code]}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </Fragment>
                                    ))
                            }
                        </Fragment>
                    )
                })
            }
            <Fragment>
                <div className="review-title">
                    <span>Management Recommendations</span>
                </div>
                <Table borderless responsive className="streambank-table half-table">
                    <tbody>
                        {
                            surveys.map(survey => (
                                survey.SurveySections
                                    .filter(section => !!section.SurveyQuestions.length)
                                    .map(section => (
                                        section.SurveyQuestions.filter(question => hasMonitoringRecommendations(question))
                                            .map(question => {
                                                // Monitoring Recommendations aren't required
                                                question.isRequired = false;

                                                return (<tr key={question.surveyQuestionId}>
                                                    <td key={1}>{section.name}</td>
                                                    <td key={2}>
                                                        <SurveyQuestionAnswer
                                                            question={question}
                                                            answer={answers?.[question.code]}
                                                        />
                                                    </td>
                                                </tr>);
                                            })
                                    ))
                            ))
                        }
                    </tbody>
                </Table>
            </Fragment>
        </div>
    );
};

export default QualitativeVegMonitoringReviewTable;
