import {useDispatch, useSelector} from "react-redux";
import {UPSERT_QUAL_VEG_MONITORING, UPDATE_TRANSECT_VALUE} from "../Redux/Actions/Types/offlineDataActionTypes";
import {selectTransect} from "../Redux/Selectors/transectSelectors";
import DateUtils from "../Utils/DateUtils";
import {useNextTempId} from "./TempIdHooks";
import {useEffect} from "react";
import {selectSampleEventById} from "../Redux/Selectors/nodeSelectors";
import {useMostRecentSampleEvent} from "./DataCopyHooks";
import {addRepeaterItem} from "../Redux/Actions/surveyActions";
import {selectQualitativeVegetationMonitoringBySampleEventId} from "../Redux/Selectors/qualitativeSelectors";

export const useCreateTransectNarrative = (transectId) => {
    const dispatch = useDispatch();

    const user = useSelector(state => state.userState.user);
    const transect = useSelector(state => selectTransect(state, transectId));

    useEffect(() => {
        if (transect && !transect.TransectNarrative) {
            dispatch({
                type: UPDATE_TRANSECT_VALUE,
                field: 'TransectNarrative',
                transectId,
                value: {
                    transectId,
                    sampleEventId: transect.sampleEventId,
                    speciesProtected: '',
                    competingVegetationNotes: '',
                    wildlifeSightings: '',
                    managementRecommendations: '',
                    TransectNarrativeIrrigation: [],
                    TransectNarrativeBrowse: [],
                    TransectNarrativeVigor: [],
                    createdByUserId: user.userId,
                    offlineCreatedDate: DateUtils.GetCurrentDateTime(), // because there is no ADD_TRANSECT_VALUE
                },
                download: true,
            });
        }
    }, []);
};
