import {
    SET_CONNECTION,
    INCREMENT_TEMP_ID,
    TOGGLE_ACCOUNT_MENU,
    CLOSE_ACCOUNT_MENU,
    TOGGLE_QUICK_NAV,
    CLOSE_QUICK_NAV,
    SET_QUICK_NAV_LINKS,
    ACCEPT_TERMS,
    CLOSE_TERMS,
    OPEN_TERMS,
    OPEN_BACKUP,
    CLOSE_BACKUP,
    SET_OFFLINE_MODE,
    SET_PARTIAL_LOGIN,
    SET_BREADCRUMBS,
    SET_CONTEXT_TABS,
    SET_PROCEDURES,
    SET_RETURN_PATH,
    CLEAR_RETURN_PATH, SET_PROJECT_MAP, OPEN_UPDATE_MODAL, CLOSE_UPDATE_MODAL, SET_HIDE_DASHBOARD_SIDEBAR
} from "../Actions/Types/appStatusTypes";
import {USER_LOGOUT} from "../Actions/Types/userActionTypes";
import {RESTORE_BACKUP} from "../Actions/Types/offlineDataActionTypes";

const initialState = {
    online: false,
    partialLogin: false,
    offlineMode: false,
    termsAccepted: false,
    tempId: 1,
    accountMenuOpen: false,
    quickNavOpen: false,
    termsOpen: false,
    backupOpen: false,
    updateOpen: false,
    quickNavLinks: [],
    breadcrumbs: [],
    contextTabs: [],
    procedures: [],
    projectMap: null,
    returnPath: null,
    returnTitle: null,
    hideDashboardSidebar: true,
};

const appStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONNECTION:
            return {
                ...state,
                online: action.online,
            };
        case SET_PARTIAL_LOGIN:
            return {
                ...state,
                partialLogin: action.partialLogin,
            };
        case SET_OFFLINE_MODE:
            return {
                ...state,
                offlineMode: action.offlineMode,
            };
        case ACCEPT_TERMS:
            return {
                ...state,
                termsAccepted: true,
            };
        case TOGGLE_ACCOUNT_MENU:
            return {
                ...state,
                accountMenuOpen: action.accountMenuOpen,
            };
        case CLOSE_ACCOUNT_MENU:
            return {
                ...state,
                accountMenuOpen: false,
            };
        case SET_QUICK_NAV_LINKS:
            return {
                ...state,
                quickNavLinks: action.quickNavLinks,
            };
        case TOGGLE_QUICK_NAV:
            return {
                ...state,
                quickNavOpen: action.quickNavOpen,
            };
        case CLOSE_QUICK_NAV:
            return {
                ...state,
                quickNavOpen: false,
            };
        case OPEN_TERMS:
            return {
                ...state,
                termsOpen: true,
            };
        case CLOSE_TERMS:
            return {
                ...state,
                termsOpen: false,
            };
        case OPEN_BACKUP:
            return {
                ...state,
                backupOpen: true,
            };
        case CLOSE_BACKUP:
            return {
                ...state,
                backupOpen: false,
            };
        case OPEN_UPDATE_MODAL:
            return {
                ...state,
                updateOpen: true,
            };
        case CLOSE_UPDATE_MODAL:
            return {
                ...state,
                updateOpen: false,
            };
        case INCREMENT_TEMP_ID:
            return {
                ...state,
                tempId: state.tempId + 1,
            };
        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.breadcrumbs,
            };
        case SET_CONTEXT_TABS:
            return {
                ...state,
                contextTabs: action.contextTabs,
            };
        case SET_PROCEDURES:
            return {
                ...state,
                procedures: action.procedures,
            };
        case SET_PROJECT_MAP:
            return {
                ...state,
                projectMap: action.projectMap,
            };
        case SET_RETURN_PATH:
            return {
                ...state,
                returnPath: action.path,
                returnTitle: action.title,
            };
        case SET_HIDE_DASHBOARD_SIDEBAR:
            return {
                ...state,
                hideDashboardSidebar: action.hideDashboardSidebar,
            };
        case CLEAR_RETURN_PATH:
            return {
                ...state,
                returnPath: null,
                returnTitle: null,
            };
        case USER_LOGOUT:
            return {
                ...initialState,
                online: navigator?.onLine ?? false
            };
        case RESTORE_BACKUP:
            return {
                ...action.data.appStatusState,
                updateOpen: false,
            };
        default:
            return state;
    }
};

export default appStatusReducer;
