/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {createSelector} from "reselect";
import {
	LIST_INVASIVE_GROUND_COVER,
	LIST_NATIVE_OR_UNKNOWN_WOODY,
	LIST_NATIVE_WOODY,
	LIST_NON_INVASIVE_TREES,
	LIST_NON_INVASIVE_WOODY,
	LIST_PROBLEMATIC
} from "../../Constants/species";
import {selectProject} from "./nodeSelectors";

/**
 * @type {(state: DefaultRootState, projectId) => Array}
 */
export const selectAllSpecies = createSelector(
	[selectProject],
	(project) => {
		return project?.Species;
	}
)

/**
 * @type {(state: DefaultRootState, projectId) => Array}
 */
export const selectSelectableSpecies = createSelector(
	[selectAllSpecies],
	(allSpecies) => {
		return allSpecies?.filter(x => !x.isHidden);
	}
);

export const selectSpeciesList = createSelector(
	[
		selectSelectableSpecies,
		(state, projectId, speciesListType) => speciesListType,
		(state, projectId, speciesListType, selectedSpeciesId) => selectedSpeciesId
	],
	(selectableSpecies, speciesListType, selectedSpeciesId) => {
		const defaultFilter = (item) => (
			(item.speciesTypeId === 5 || (selectedSpeciesId !== null && item.speciesId === selectedSpeciesId))
			&& item.isHidden !== 1
		);
		
		switch (speciesListType) {
			// Used on Stem Tally, and Length of Cover
			case LIST_NON_INVASIVE_WOODY:
				return selectableSpecies.filter(item =>
					(item.speciesInvasivityId === 0 && item.speciesTypeId < 4) || defaultFilter(item)
				);
			// Used on DBH
			case LIST_NON_INVASIVE_TREES:
				return selectableSpecies.filter(item =>
					(item.speciesInvasivityId === 0 && item.speciesTypeId === 1) || defaultFilter(item)
				);
			// Used on Invasive + Ground Cover
			case LIST_INVASIVE_GROUND_COVER:
				return selectableSpecies.filter(item =>
					// include invasive species herbaceous species
					// Monique mentioned that we don't include non-invasive
					// herbaceous species because we won't want people
					// to spend time documenting that detail.
					// note: we intentionally allow speciesTypeId -1
					// here since we want to allow 'Not Applicable'
					(item.speciesInvasivityId !== 0 && item.speciesTypeId <= 4) ||
					// include all species that begin with a number for
					// the generic, native ground cover categories
					!isNaN(Number(item.latinName.charAt(0))) ||
					defaultFilter(item)
				);
			// Used on Qualitative competition Repeater
			case LIST_PROBLEMATIC:
				return selectableSpecies.filter(item =>
					[1, 2, 3].includes(item.speciesInvasivityId) ||
					defaultFilter(item)
				);
			// Used on Qualitative performance Repeater
			case LIST_NATIVE_OR_UNKNOWN_WOODY:
				return selectableSpecies.filter(item =>
					([1, 3].includes(item.speciesNativityId) && item.speciesTypeId < 4) ||
					defaultFilter(item)
				);
			// Used on Qualitative health, browse, and insectsii Repeaters
			case LIST_NATIVE_WOODY:
				return selectableSpecies.filter(item =>
					(item.speciesNativityId === 1 && item.speciesTypeId < 4) ||
					defaultFilter(item)
				);
			default:
				return [];
		}
	}
)
