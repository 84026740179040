/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const StatusIndicator = (props) => {
    const {
        showLabel = false
    } = props;
    
    let {
        className = null,
        statusLabel = null,
    } = props;
    
    const { online, offlineMode } = useSelector(state => state.appStatusState);
    
    const defaultClassName = offlineMode ? "warning" : online ? "success" : "";
    const defaultStatusLabel = offlineMode ? 'Offline Mode' : online ? 'Online' : 'Offline';
    
    className = className ?? defaultClassName ;
    statusLabel = statusLabel ?? defaultStatusLabel;
    
    return <span className="status-indicator">
        <FontAwesomeIcon className={className}
                         icon={["fas", "circle"]}/>
        { showLabel ? <span className="label">{statusLabel}</span> : null }
    </span>;
};
