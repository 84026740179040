/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

import storage from "localforage";
import {useEffect, useState} from "react";

const key = '__healthCheck';
const checkInterval = 15000;    // 15 seconds
const warningThreshold = 60000; // 60 seconds
let healthCheckValue = Date.now();

export const useStorageHealthCheck = () => {
    
    const [timeSinceLastSuccess, setTimeSinceLastSuccess] = useState(0);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        storeHealthCheck();
        const intervalTimer = setInterval(checkHealth, checkInterval);
        return () => clearInterval(intervalTimer);
    }, []);
    
    const checkHealth = async () => {
        
        // fetch the last value and confirm
        // it stored successfully
        try {
            const storedValue = await storage.getItem(key);
            setTimeSinceLastSuccess(healthCheckValue - storedValue)
        } catch (err) {
            console.log(err);
        }
        
        storeHealthCheck();
    }
    
    const storeHealthCheck = () => {
        // Increment healthCheckValue and store it
        healthCheckValue = Date.now();
        try {
            void storage.setItem(key, healthCheckValue);
        } catch (err) {
            if (err.name === 'QuotaExceededError') {
                console.error('Storage quota exceeded!');
                // Handle the error here
            } else {
                console.error('An error occurred:', err);
            }
            setError(err);
        }
    }
    
    if(timeSinceLastSuccess > warningThreshold) {
        alert("Device database stopped not responding.\n" +
            "Please force quit and restart the StreamBank Monitoring app to avoid data loss.\n" +
            "Error was: " + (error?.name ?? "") + ": " + (error?.message ?? ""));
        setTimeSinceLastSuccess(0);
        setError(null);
    }
    
    return timeSinceLastSuccess;
}


