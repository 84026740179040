/*
 * Copyright © 2017-Present, The Freshwater Trust, all rights reserved.
 */

/**
 * Checks if the question has monitoring recommendations.
 * @param question
 * @returns {*|boolean}
 */
export const hasMonitoringRecommendations = (question) => {
    if (question.surveyQuestionDataTypeId === 13) {
        const values = question?.options?.values;
        
        if (!values) {
            return false;
        }
        
        return values.some(value => value?.secondaryFields && value?.secondaryFields.some(field => field.type === 'monitoring-recommendations'));
    }
    
    return false;
};
