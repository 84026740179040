import {createSelector} from "reselect";

export const qualitativeVegetationMonitoringsSelector = state => state.offlineDataState.qualitativeVegetationMonitorings;

export const selectQualitativeVegetationMonitoringBySampleEventId = createSelector(
	[qualitativeVegetationMonitoringsSelector, (state, sampleEventId) => sampleEventId],
	(qualitativeVegetationMonitorings, sampleEventId) => {
		return qualitativeVegetationMonitorings.find(
			qualitativeVegetationMonitoring => String(qualitativeVegetationMonitoring.sampleEventId) === String(sampleEventId)
		);
	}
);

export const selectQualitativeVegetationMonitoringById = (state, qualitativeVegetationMonitoringId) => {
	const qualitativeVegetationMonitorings = qualitativeVegetationMonitoringsSelector(state);
	return qualitativeVegetationMonitorings.find(
		qualitativeVegetationMonitoring => String(qualitativeVegetationMonitoring.qualitativeVegetationMonitoringId) === String(qualitativeVegetationMonitoringId)
	);
}

export const selectQualitativeVegetationMonitoringByRepeaterId = (state, qualitativeVegetationMonitoringRepeaterId) => {
	const qualitativeVegetationMonitorings = qualitativeVegetationMonitoringsSelector(state);
	return qualitativeVegetationMonitorings.find(
		survey => (survey?.QualitativeVegetationMonitoringRepeaters ?? []).find(
			repeater => String(repeater.qualitativeVegetationMonitoringRepeaterId) === String(qualitativeVegetationMonitoringRepeaterId)
		)
	);
}

export const selectQualitativeVegetationMonitoringRecommendationsBySampleEventId = createSelector(
	[selectQualitativeVegetationMonitoringBySampleEventId, (state, sampleEventId) => sampleEventId],
	(qualitativeEvent, sampleEventId) => {
		return qualitativeEvent?.QualitativeVegetationMonitoringRecommendations ?? [];
	}
);

export const selectQualitativeVegetationMonitoringRepeatersBySampleEventId = createSelector(
	[selectQualitativeVegetationMonitoringBySampleEventId, (state, sampleEventId) => sampleEventId],
	(qualitativeEvent, sampleEventId) => {
		return qualitativeEvent?.QualitativeVegetationMonitoringRepeaters ?? [];
	}
);
