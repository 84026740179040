import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";

import FormInput from "../FormInput";
import {OPEN_SPECIES_PICKER, SET_SELECTED_SPECIES} from "../../../Redux/Actions/Types/transectActionTypes";
import {useGetUserFacingSpeciesName} from "../../../Hooks/LabelHooks";
import {
    selectSampleEventById
} from "../../../Redux/Selectors/nodeSelectors";
import {selectAllSpecies} from "../../../Redux/Selectors/speciesSelectors";
import {selectQualitativeVegetationMonitoringBySampleEventId} from "../../../Redux/Selectors/qualitativeSelectors";


const SurveySpeciesInput = props => {
    const dispatch = useDispatch();
    const getUserFacingSpeciesName = useGetUserFacingSpeciesName();

    const { answerId, repeaterId, column, sampleEventId, disabled } = props;

    const sampleEvent = useSelector(state => selectSampleEventById(state, sampleEventId));
    const species = useSelector(state => selectAllSpecies(state, sampleEvent.projectId));
    const answers = useSelector(state => selectQualitativeVegetationMonitoringBySampleEventId(state, sampleEventId));

    const nameColumn = column.endsWith('Id') ? column.substr(0, column.length - 2) : null;
    const selectedSpecies = species.find(species => species.speciesId === props.value);

    let speciesName = getUserFacingSpeciesName(selectedSpecies);

    // if we fail to get a speciesName from the "Id" column
    // then look for the name in the text column (historical data)
    if(!speciesName && nameColumn) {
        const repeater = answers.QualitativeVegetationMonitoringRepeaters.find(repeater => repeater.qualitativeVegetationMonitoringRepeaterId === repeaterId);
        speciesName = repeater?.[nameColumn] ?? '';
    }

    return (
        <Fragment>
            <div className="survey-species-input">
                <FormInput
                    key={"species" + "_" + repeaterId}
                    label={props.label}
                    placeholder={props.placeholder}
                    type="text"
                    value={speciesName ?? ''}
                    onClick={() => {
                        if (props.readOnly) {
                            return;
                        }

                        dispatch({type: SET_SELECTED_SPECIES, speciesId: selectedSpecies?.speciesId});
                        dispatch({
                            type: OPEN_SPECIES_PICKER,
                            species: props.listType,
                            answerId: answerId,
                            repeaterId: repeaterId,
                            idColumn: column,
                            nameColumn: nameColumn
                        });
                    }}
                    disabled={disabled}
                    readOnly
                    required={props.required}
                />
            </div>
        </Fragment>
    );
};

export default SurveySpeciesInput;
