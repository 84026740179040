import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Button, Navbar} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AccountMenu from "./AccountMenu";
import {
    CLEAR_RETURN_PATH,
    CLOSE_ACCOUNT_MENU,
    CLOSE_QUICK_NAV,
    TOGGLE_ACCOUNT_MENU,
    TOGGLE_QUICK_NAV
} from "../../../Redux/Actions/Types/appStatusTypes";
import QuickNav from "./QuickNav";
import {useClearNav} from "../../../Hooks/QuickNavHooks";
import {selectUploadCount} from "../../../Redux/Selectors/hierarchySelectors";
import CircledTextIcon from "../../CompositeIcons/CircledTextIcon";
import Breadcrumbs from "./Breadcrumbs";
import ContextTabs from "./ContextTabs";
import {StatusIndicator} from "./StatusIndicator";

const HeaderComponent = props => {
    useClearNav();

    const dispatch = useDispatch();
    const history = useHistory();

    const {returnPath, returnTitle} = useSelector(state => state.appStatusState);
    const uploadCount = useSelector(state => selectUploadCount(state));

    const {accountMenuOpen, quickNavLinks, quickNavOpen} = useSelector(state => state.appStatusState);

    const returnToPath = () => {
        history.push(returnPath);
        dispatch({type: CLEAR_RETURN_PATH});
    };

    // TODO: make this it's own component
    const renderReturnBar = () => {
        if (returnPath) {
            return (
                <Navbar fixed="top" className="return-bar">
                    <Button variant="complete" onClick={() => returnToPath()}>
                        Back to {returnTitle}
                        <FontAwesomeIcon icon={['fas', 'redo']} transform="flip-h" />
                    </Button>
                    <FontAwesomeIcon icon={['fas', 'times']} onClick={() => dispatch({type: CLEAR_RETURN_PATH})} />
                </Navbar>
            );
        }

        return null;
    };

    return (
        <Fragment>
            <Navbar fixed="top" className="justify-content-between">
                <div className="left-nav-bar">
                    <div className="header-logo">
                        <StatusIndicator />
                        <img
                            src="/images/sbm-icon-white-no-trademark.svg"
                            alt="logo"
                            onClick={() => history.push('/dashboard')}
                        />
                    </div>
                    <Breadcrumbs/>
                </div>
                <ContextTabs/>
                {/*<div className="context-tools">*/}
                {/*{*/}
                {/*contextTools.map(tool => (*/}
                {/*<CircledIcon*/}
                {/*key={tool.code}*/}
                {/*icon={tool.icon}*/}
                {/*circleSize="20"*/}
                {/*iconTransform="grow-2"*/}
                {/*onClick={() => tool.action()}*/}
                {/*/>*/}
                {/*))*/}
                {/*}*/}
                {/*</div>*/}
                <div className="menu-icons">
                    {
                        !!quickNavLinks.length &&
                        <div
                            className="menu-icon"
                            onClick={() => {
                                dispatch({ type: CLOSE_ACCOUNT_MENU });
                                dispatch({ type: TOGGLE_QUICK_NAV, quickNavOpen: !quickNavOpen });
                            }}
                        >
                            <FontAwesomeIcon icon={['fal', 'bolt']} className={quickNavOpen ? 'active' : ''}/>
                        </div>
                    }
                    <div
                        className="menu-icon"
                        onClick={() => {
                            dispatch({ type: CLOSE_QUICK_NAV });
                            dispatch({ type: TOGGLE_ACCOUNT_MENU, accountMenuOpen: !accountMenuOpen });
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'bars']} className={accountMenuOpen ? 'active' : ''}/>
                        {
                            !!uploadCount &&
                            <CircledTextIcon
                                circleSize={3}
                                text={uploadCount}
                                className="danger"
                            />
                        }
                    </div>
                </div>
            </Navbar>
            {renderReturnBar()}
            <AccountMenu show={accountMenuOpen}/>
            <QuickNav show={quickNavOpen}/>
        </Fragment>
    )
};

export default HeaderComponent;
